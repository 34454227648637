import {Helmet} from 'react-helmet-async';
import React, {ReactElement} from 'react';
import classNames from 'classnames';
import {useSelector} from 'react-redux';

import MarketLayout, {reducer as marketLayoutReducer} from 'web/components/market_layout';
import MarketTile from 'web/components/market_tile';
import MarketSidebar from 'web/components/market_sidebar';
import {PageType} from 'web/helpers/redux_client';

import {StoreData} from './controller';

const segmentFeature = 'favorites';

const FavoritesPage: PageType<StoreData> = () => {
  const recentlyAddedProductIds = useSelector((state: StoreData) => state.recentlyAddedProductIds);
  const products = useSelector((state: StoreData) => state.products);
  const basketItemsByProductId = useSelector((state: StoreData) => state.basketItemsByProductId);
  const currentFulfillmentDay = useSelector((state: StoreData) => state.currentFulfillmentDay);
  const fulfillmentDaySummaries = useSelector((state: StoreData) => state.fulfillmentDaySummaries);
  const favorites = useSelector((state: StoreData) => state.favorites);
  const principalCategorySlug = useSelector((state: StoreData) => state.principalCategorySlug);
  const gf = useSelector((state: StoreData) => state.groupedFavorites);
  const groupedFavorites =
    gf?.map((groupedFavorite) => ({
      ...groupedFavorite,
      id: groupedFavorite.name.toLowerCase().replace(/[^a-zA-Z]+/g, '-'),
    })) ?? [];
  const createMarketSidebarData = (): any[] => {
    let menuGroups = groupedFavorites?.map((groupedFavorite) => ({
      displayName: groupedFavorite.name,
      id: groupedFavorite.id,
      menuItems: [],
    }));
    if (recentlyAddedProductIds?.length > 0) {
      menuGroups = [
        {
          displayName: 'Recently Added',
          id: 'recently-added',
          menuItems: [],
        },
      ].concat(menuGroups);
    }
    return menuGroups ?? [];
  };

  const renderSidebar = (): ReactElement => {
    const menuGroups = createMarketSidebarData();
    return (
      <div className="favorites-page__sidebar">
        <MarketSidebar menuGroups={menuGroups} pushAnchor="false" />
      </div>
    );
  };

  const renderRecentlyAdded = (): ReactElement | null => {
    return (
      <div className="product-listings-view">
        <div className="product-tiles">
          <div
            className="section-page"
            id="favorites-page__category-recently-added"
            key="recently-added-products"
          >
            <div id="recently-added" className="favorites-page__category-anchor" />
            <div className="favorites-page__category-title">Recently Added</div>
            <div className="favorites-page__products">
              {recentlyAddedProductIds?.map((id: string, i: number) => {
                const product = products[id];
                let quantityInBasket = 0;
                if (basketItemsByProductId?.[id] !== undefined) {
                  quantityInBasket = basketItemsByProductId[id].quantity ?? 0;
                }
                return (
                  <MarketTile
                    key={id}
                    position={i}
                    currentFulfillmentDay={currentFulfillmentDay}
                    fulfillmentDaySummaries={fulfillmentDaySummaries}
                    product={product}
                    quantityInBasket={quantityInBasket}
                    isFavorited={favorites.includes(id)}
                    segmentFeature={segmentFeature}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderFavoritesByGroup = (): ReactElement => {
    return (
      <div className="product-listings-view">
        <div className="product-tiles">
          {groupedFavorites.map((groupedFavorite: any, i: number) => (
            <div
              className="section-page"
              id={`favorites-page__category-${groupedFavorite.id}`}
              key={groupedFavorite.id}
            >
              <div id={groupedFavorite.id} className="favorites-page__category-anchor" />
              <div className="favorites-page__category-title">{groupedFavorite.name}</div>
              <div className="favorites-page__products">
                {groupedFavorite.productIds?.map((id: string) => {
                  const product = products[id];
                  let quantityInBasket = 0;
                  if (basketItemsByProductId?.[product.id] !== undefined) {
                    quantityInBasket = basketItemsByProductId[product.id].quantity ?? 0;
                  }
                  return (
                    <MarketTile
                      key={id}
                      product={product}
                      position={i}
                      currentFulfillmentDay={currentFulfillmentDay}
                      fulfillmentDaySummaries={fulfillmentDaySummaries}
                      segmentFeature={segmentFeature}
                      quantityInBasket={quantityInBasket}
                      isFavorited={favorites.includes(product.id)}
                    />
                  );
                })}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const renderFavorites = (): ReactElement => {
    return (
      <div className="favorites-page__container">
        <h1 className="favorites-page__title">Favorites</h1>
        {recentlyAddedProductIds?.length > 0 && renderRecentlyAdded()}
        {groupedFavorites?.length > 0 && renderFavoritesByGroup()}
      </div>
    );
  };

  const renderEmptyPage = (): ReactElement => {
    return (
      <div className="favorites-page__container">
        <h1 className="favorites-page__title">Favorites</h1>
        <div className="favorites-page__body">
          <div className="favorites-page__body-text">
            Collect your favorites by clicking the heart icon on the products you like.
          </div>
          <a href={`/${principalCategorySlug}`} className="favorites-page__demo-tile" />
        </div>
      </div>
    );
  };

  const emptyPage = recentlyAddedProductIds?.length === 0;

  return (
    <MarketLayout>
      <Helmet>
        <title>Your Favorites | Good Eggs</title>
      </Helmet>

      <div
        className={classNames('favorites-page', {
          'is-empty': emptyPage,
          'is-not-empty': !emptyPage,
        })}
        data-attribution-feature="favorites"
      >
        {renderSidebar()}
        {emptyPage ? renderEmptyPage() : renderFavorites()}
      </div>
    </MarketLayout>
  );
};

FavoritesPage.pageName = 'Favorites';
FavoritesPage.reducer = (state, action) => {
  // TODO: (@shermam) This is not ideal, but the state should always be set here
  // since we preload it from the controller. We should maybe come up with a better
  // way of asserting the type here
  if (!state) throw new Error('State should always be preloaded here');

  return marketLayoutReducer(state, action);
};

export default FavoritesPage;
